.nounWrapper {
  align-self: flex-end;
  width: 100%;
  z-index: -1;
}
.nounContentCol {
  display: flex;
}

.pageContentWrapper {
  text-align: center;
}

.currentAuctionActivityContainer {
  margin-top: 5vh;
  text-align: left;
  z-index: 2;
  align-self: flex-end;
  /* for nouns stamp in safari browsers */
}

@media (max-width: 992px) {
  .currentAuctionActivityContainer {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }
}

.pastAuctionActivityContainer {
  margin-top: 10vh;
  text-align: left;
}

@media (max-width: 992px) {
  .pastAuctionActivityContainer {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }
}

.auctionActivityCol {
  padding-top: 2rem;
  align-self: start !important; /* maintains auction arrow buttons fixed from auction to auction  */
}

@media (max-width: 992px) {
  .auctionActivityCol {
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2rem;
  }
}

.noPaddingMargin {
  padding: 0px;
  margin: 0px;
}
