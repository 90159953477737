.headerWrapper h1 {
  font-size: 4rem;
  color: #212529;
  font-weight: 900;
  padding: 0rem 1.25rem;
}
.headerWrapper p {
  margin: 3rem 0;
  padding: 0rem 1.25rem;
}

.faq {
  font-weight: 900;
  font-size: 2.5rem;
  color: #212529;
}

.teamRewards {
  font-weight: 900;
  font-size: 2.5rem;
  color: #212529;
}

@media (max-width: 992px) {
  .headerWrapper {
    padding: 1rem;
  }

  .headerWrapper h1 {
    font-family: 'Fredoka One';
    font-size: 4rem;
    background: -webkit-linear-gradient(10deg, #00ffa3 10%, #57cdff 40%, #f68eff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .faq {
    font-family: 'Fredoka One';
    font-size: 2.5rem;
    color: #212529;
  }
  .teamRewards {
    font-family: 'Fredoka One';
    font-size: 2.5rem;
    color: #212529;
  }
}

.card {
  border: none;
}

.card,
.headerWrapper {
  font-size: 1.3rem;
}
.cardHeader {
  border: none;
  background-color: transparent;
  font-size: 2.5rem;
  cursor: pointer;
}

.documentationParagraph {
  margin-bottom: 3rem;
  font-size: 1.125rem;
}

.collectiveParagraph {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.weAreTheBuildersBaby {
  margin-bottom: 3rem;
}

/* .cardHeader h2 {
  font-family: 'Fredoka One';
  font-size: 2.5rem;
  background: -webkit-linear-gradient(10deg, #00ffa3 5%, #57cdff 10%, #f68eff 15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
