.claimTokensButton {
  height: 3em;
  width: 50%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 30px 0px 10px 0px;
}

.tokenRowText {
  margin: 0px;
  padding: 0px;
}

.infoText {
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 10px;
  padding: 8px 0px;
  font-weight: bold;
}

.row:nth-child(odd) {
  background-color: #f7f7f7;
}
