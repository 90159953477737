.partyVaultWrapper {
  margin: 20px 25px 25px 25px;
}
.ethNeededText {
  font-size: 18px;
  color: rgba(33, 37, 41, 0.2);
}

.partyTrackerText {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2px;
}

.partyGoalText {
  font-size: 1rem;
  font-weight: 500;
  text-align: end;
  color: rgb(33, 37, 41);
  margin-bottom: 0px;
  align-items: center;
}

.ethXiFont {
  /* background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: rgba(104, 116, 127, 0.3);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.progressBarContainer {
  display: grid;
}

.progressBar {
  grid-column: 1;
  grid-row: 1;
}

.blurProgressBar {
  filter: blur(6px);
  z-index: -2;
}

@media (max-width: 992px) {
  .ethNeededText {
    text-align: end;
    font-size: 0.9em;
    color: rgba(33, 37, 41, 0.2);
  }
  .partyTrackerText {
    font-size: 1rem;
    font-weight: bold;
  }
}
