@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
.partyPaperContainer {
  background-color: white;
  border-bottom: none;
  padding-top: 20px;
  box-shadow: 0px -8px 8px rgb(0 0 0 / 20%);
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
}

@media (min-width: 993px) {
  .paperWrapper {
    height: 460px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px;
  }
}

.partyPaperContainer:before {
  position: absolute;
  top: -20px;
  display: block;
  content: '';
  background: radial-gradient(circle, #ffffff00 12px, #ffffff 13px, white 5px);
  background-size: 44px 42px;
  background-position: 6px -22px;
  width: 100%;
  height: 40px;
  z-index: -1;
}

.floatingPaper {
  background-color: white;
  /* box-shadow: 0px -8px 8px rgb(0 0 0 / 20%); */
  padding-top: 20px;
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  scrollbar-color: #cacfd2 rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
  width: 10px;
  padding: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eeeff2;
  border-radius: 24px;

  border: none;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #cacfd2;
  border-radius: 24px;
}

.auctionActivityContainer {
  padding: 0px;
  margin: 10px;
}

.informationRow {
  margin-bottom: 2rem;
}

.activityRow {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.modalHeader {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.modalTitleWrapper {
  color: var(--brand-black);
}

.modalTitleWrapper h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.modalHeader button {
  align-self: flex-start;
}

.modalHeaderNounImgWrapper {
  width: 150px;
  height: 150px;
}

/* come on safari what in the world */

/* https://stackoverflow.com/questions/41901346/div-with-position-fixed-not-displaying-inside-div-with-position-absolute-in-sa */

.nounsPartyStamp {
  position: absolute;
  top: -40px;
  right: -15px;
  z-index: -1;
  height: 200px;
  width: 200px;
  opacity: 0.5;
}

.winningAmount {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
}

.winningBidRow {
  margin-bottom: 20px;
}

/* Fix Firefox navigation arrow alignment issues */

@-moz-document url-prefix() {
  .colAlignCenter {
    display: flex;
    align-items: center;
  }
}

.nounIdContainer {
  margin: 10px 10px 10px 10px;
  font-family: 'Fredoka One';
  padding-left: 15px;
  display: flex;
}

.nounIdText {
  font-family: 'Fredoka One';
  /* background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 3em;
  padding: 0px;
  margin: 0px 0px 0px 0px;
  white-space: nowrap;
  color: #212529;
}

.buttonsWrapper {
  margin: 10px;
}

.fracVaultContainer {
  margin: 5px 25px;
}

.fracVaultButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 5px 0px 5px 0px;
}

.settleAuctionButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}

.ethAddressPadding {
  padding-left: 1em;
  padding-right: 0px;
}

.noMarginPadding {
  margin: 0px !important;
  padding: 0px !important;
}

.addressText {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 40px;
  font-weight: 700;
  color: #212529;
}

.bidText {
  font-weight: 700;
  font-size: 1.125rem;
}

.dateContainer {
  display: flex;
}

.noPaddingMargin {
  margin: 0px;
  padding: 0px;
}

.auctionTimerContainer {
  margin: 0px 10px 10px 10px;
  padding-left: 15px;
}

.currentAuctionActivityContainer {
  margin-top: 5vh;
  text-align: left;
  z-index: 2;
  align-self: flex-end;
  /* for nouns stamp in safari browsers */
}

@media (max-width: 992px) {
  .floatingPaper {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 20px;
  }
  .wrapper {
    padding: 1rem;
  }
  .auctionTimerCol {
    padding-left: 15px;
  }
  .nounsPartyStamp {
    position: absolute;
    top: 70px;
    right: 0px;
    z-index: -1;
    height: 150px;
    width: 150px;
    opacity: 0.5;
  }
  .winningAmount {
    margin: 0px !important;
    padding: 0px 0px 15px 0px !important;
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
  }

  .currentAuctionActivityContainer {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }

  .paperWrapper {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 0px;
  }
}

.settleAuctionRow {
  margin: 0px 10px 0px 10px;
}
