.buttonsWrapper {
  margin: 10px;
}

.auctionInfoWrapper {
  background-color: white;
  border-radius: 12px;
  padding: 15px;
}

.connectWalletButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  background: #3210ff;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  font-size: 1em;
}
.invitePartyButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: bold;
  color: #fff;
  background: black;
  cursor: pointer;
  z-index: 0;
  border-radius: 12px;
  font-size: 1em;
}

.noLeftPadding {
  padding-left: 0px;
  padding-right: 10px;
}

.noRightPadding {
  padding-left: 10px;
  padding-right: 0px;
}
