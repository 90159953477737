.leftArrow {
  display: inline-block;
  border: none;
  background-color: #6db3f2;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 3;
  align-self: end;
  /* margin-left: 10px;
  margin-top: 10px; */
}
.rightArrow {
  display: inline-block;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 3;
  align-self: end;
  /* margin-top: 12px; */
}

.rightArrow img {
  transform: rotate(180deg);
}

.leftArrow:hover,
.rightArrow:hover {
  opacity: 0.9;
}
.leftArrow:disabled,
.rightArrow:disabled {
  opacity: 0.2;
}
/*
@media (max-width: 992px) {
  .leftArrow {
    margin-left: 1rem;
  }
}*/
