.bidText {
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0px !important;
  padding: 0px !important;
  color: #212529;
}

.addressText {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
}

/* Help prevent overflow */
.ethAddressPadding {
  padding-left: 1em;
  padding-right: 0px;
}

.partyWonText {
  background: -webkit-linear-gradient(10deg, #00ffa3 12%, #57cdff 35%, #f68eff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-weight: 700;
}

@media (max-width: 993px) {
  .addressText {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
  }
}
