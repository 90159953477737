.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 25vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  background-color: white;
  padding-top: 3rem;
  text-align: center;
  left: calc(50% - 15rem);
  width: 34rem;
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
  border-radius: 24px;
}

@media (max-width: 992px) {
  .modal {
    max-width: 100vw;
    max-height: 100vh;
  }
}

.modal h3 {
  font-size: xx-large;
  font-weight: bold;
}

.content {
  padding: 1.5rem 3rem 4rem 3rem;
  max-height: 50vh;
  overflow-y: auto;
}

.modal .closeButton {
  position: absolute;
  top: 1rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
}
.modal button img {
  width: 1rem;
  height: auto;
}

@media (max-width: 992px) {
  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
