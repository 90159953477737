.cursor {
  z-index: 99999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  color: var(--brand-black);
  border-radius: 20px;
}

.cursor i {
  font-style: normal;
  font-size: x-large;
}

.cursor input {
  border: 0;
  margin-left: 4px;
  font-size: small;
  width: 200px;
  background-color: var(--white);
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90.72deg, #00ffa3 20%, #57cdff 33.54%, #f68eff 90%) border-box;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--brand-black);
}

.cursor input:focus {
  outline: none;
}

.nounHeadImage {
  height: 60px;
  width: 60px;
  margin-left: -20px;
  margin-top: -2px;
}
