.vaultText {
  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;
  color: #19eea1;
}

.noMarginPadding {
  margin: 0px !important;
  padding: 0px !important;
}

.ethAddressPadding {
  padding-left: 1em;
  padding-right: 0px;
}

.addressText {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
  text-align: center;
}

@media (max-width: 992px) {
  .addressText {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 1.75rem;
    font-weight: 700;
    color: #212529;
    text-align: center;
    white-space: nowrap;
  }
}

.ethXiFont {
  font-weight: 700;
}
