.footerSignature {
  margin: 0 auto;
  font-family: 'Londrina Solid';
  font-size: 1rem;
}
.footerSignature a {
  text-decoration: none;
  color: black;
  margin: 0 5px;
}
.footerSignature a:hover {
  text-decoration: underline;
  animation-name: slidein;
}
.footerSignature img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.cursorInstructions {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: rgba(128, 128, 128, 0.75);
}

@media screen and (max-width: 600px) {
  .cursorInstructions {
    visibility: hidden;
   
    display: none;
  }
}

.cursorInstructions p {
  margin: 0px;
  padding: 2.5px;
  font-size: .7em;
}

.codeText {
  background-color: #b1b1b1;
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
}

.codeText:hover {
  background-color: #3f3f3f;
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
}
