.instructionsList {
  padding-bottom: 10px;
}

.instructionsTitle {
  margin: 15px 0px 15px 0px;
}

.networkName {
  margin: 0px 0px 15px 0px;
}
