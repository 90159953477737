.bidWrapper {
  margin: 1rem 0;
}

.bidBtn {
  margin-left: 1rem;
  width: 40%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.bidBtn:hover,
.bidBtn:active,
.bidBtn:focus,
.bidBtn:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}
.minBidCopy {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}
.bidInput {
  width: 60%;
  height: 3rem;
  color: black;
  border: 1px solid #aaa !important;
  border-radius: 25px !important;
  background-color: white;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}
.bidInput:focus {
  border: none !important;
  border: 1px solid #aaa !important;
}
.customPlaceholder {
  position: absolute;
  top: 25%;
  left: 85%;
  font-weight: bold;
  color: #aaa;
  z-index: 3;
}
.bidBtnAuctionEnded {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.bidBtnAuctionEnded:hover,
.bidBtnAuctionEnded:active,
.bidBtnAuctionEnded:focus,
.bidBtnAuctionEnded:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.bidInputAuctionEnded {
  display: none;
}

.bidInput:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.bidInput::-webkit-outer-spin-button,
.bidInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.bidInput[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 992px) {
  .bidInput {
    border-color: #c3c3c3;
  }
  .customPlaceholder {
    left: 44%;
  }
}

.infoText {
  text-align: left;
  font-size: 0.9em;
}

.placePartyBidButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: bold;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}
