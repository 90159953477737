.dateWrapper {
  margin: 15px 20px 0px 10px;
  padding: 0px 0px 0px 15px;
}

.noPaddingMargin {
  padding: 0px;
  margin: 0px;
}

.dateText {
  margin: 0px;
  padding: 0px;
  font-size: 1.125rem;
  font-weight: 700;
}

@media (max-width: 992px) {
  .dateText {
    margin: -10px 0px 0px 0px;
    padding: 0px;
    font-size: 1.125rem;
    font-weight: 700;
  }
}
