.infoText {
  text-align: justify;
  font-size: 0.8em;
}

.warningText {
  text-align: justify;
  font-size: 0.8em;
}

.depositText {
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.bidInput {
  border-radius: 25px !important;
  width: 50%;
  padding: 20px;
  width: 200px;
  height: 15px;
}

.customPlaceholder {
  position: absolute;
  top: 25%;
  left: 80%;
  font-weight: bold;
  color: #aaa;
  z-index: 3;
}

.inputContainer {
  margin: 10px 0px 10px 0px;
}

.addFundsButton {
  height: 3em;
  width: 100%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}
.minimumDeposit {
  font-size: 0.8rem;
  text-align: left;
  color: rgba(33, 37, 41, 0.534);
  padding: 0px;
  margin: 0px 0px 0px 20px;
}

.minimumDepositErrorMessage {
  font-size: 0.8rem;
  text-align: left;
  color: rgba(255, 0, 0);
  padding: 0px;
  margin: 0px 0px 0px 20px;
}
