.timerWrapper {
  display: flex;
  text-align: center;
  margin: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
  white-space: nowrap;
}

.timerSection {
  margin-right: 0.5rem;
}

.noPaddingMargin {
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}

.timerText {
  font-weight: 700;
  font-size: 1.125rem;
}

.timeLabels {
  font-size: 1.75rem;
}
