.guestListContainer {
  margin: 10px 10px 10px 10px;
  padding: 15px;
}

.guestListBidsContainer {
  max-height: 20vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contributionsBlurb {
  font-size: 0.8em;
}

.partyMembersHeadingContainer {
  padding: 0px;
  margin: 0px 0px 5px 0px;
}

.partyMembersHeadingText {
  vertical-align: middle;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
}

.noPaddingMargin {
  padding: 0px;
  margin: 0px;
}

.jazzicon {
  height: 30px;
  width: 30px;
  border: 2px solid black;
  padding: 0;
  margin: 0;
  border-radius: 30px;
  margin-right: 10px;
  margin-top: -3px;
}

/* User Bids classes */

.userBidsContainer {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 3px;
  overflow: auto;
  height: 37.5vh;
  border-radius: 4px;
}

.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
}

.bidRow {
  /* padding: 0.5rem; */
  /* border-bottom: 1px solid rgba(211, 211, 211, 0.322); */
  border-radius: 4px;
  background-color: #eeeff2;
  padding: 9px 14px 6px 8px;
  border-radius: 50px;
}

.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}

.bidder {
  color: var(--brand-black);
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  align-items: center;
}

.bidDate {
  color: gray;
}

.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bidAmount {
  color: var(--brand-black);
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
}

.linkSymbol,
.linkSymbol a {
  color: var(--brand-black);
  margin-bottom: 2px;
}

.linkSymbol a:hover {
  color: gray;
}

.partyBidsTitle {
  margin: 15px 0px 0px 0px;
  padding: 0px;
  font-size: 1.5rem;
  text-align: center;
}

@media (max-width: 992px) {
  .bidAmount {
    color: var(--brand-black);
    margin-right: 0.25rem;
    font-size: 0.9rem;
    font-weight: 700;
  }

  .bidder {
    color: var(--brand-black);
    display: flex;
    font-size: 0.9rem;
    font-weight: 700;
  }
}
