.proposalEditor {
  margin-top: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput,
.bodyInput {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput {
  font-size: 1.25rem;
}

.bodyInput {
  min-height: 340px;
}

.divider {
  width: 100%;
  margin: 0 0 0.5rem 0;
}
