.connectWalletButton {
  height: 3em;
  width: 100%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: #212529;
  /*background:
    linear-gradient(180deg, #ffffff75 15.22%, #ffffff00 56.54%),
    linear-gradient(90.72deg, #00ffa3 -7.78%, #57cdff 35.54%, #f68eff 118.48%);*/
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 0px 0px 10px 0px;

  transition: all 0.3s ease-in-out;
}

.connectWalletButton:hover {
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
}

.connectWalletButton:disabled {
  background: #f0f1f4;
  color: #d2d5da;
}

/*
.connectWalletButton:hover {
  background:
    linear-gradient(180deg, #ffffff7d 22.22%, #ffffff00 69.54%),
    linear-gradient(90.72deg, #3fffba -33.78%, #81d9ff 35.54%, #f8aaff 118.48%);
  box-shadow: 0px 6px 12px rgb(0 0 0 / 20%);
}*/
