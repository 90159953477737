@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Nunito', sans-serif;
}

/*  Global Defaults */
* {
  /* font-family: -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Nunito', sans-serif;
}

p {
  padding: 0px;
  margin: 0px;
}

:root {
  --brand-bg-green: #edf2f0;
  --brand-dark-red: #d63c5e;
  --brand-light-green: #6da886;
  --brand-black: #212529;
}

.App_greyBg__2L290 {
  background-color: #d5d7e1;
}
.App_beigeBg__1oxYj {
  background-color: #e1d7d5;
}

.Modal_backdrop__3FAPo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.Modal_modal__P3_V5 {
  position: fixed;
  top: 25vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  background-color: white;
  padding-top: 3rem;
  text-align: center;
  left: calc(50% - 15rem);
  width: 34rem;
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
  border-radius: 24px;
}

@media (max-width: 992px) {
  .Modal_modal__P3_V5 {
    max-width: 100vw;
    max-height: 100vh;
  }
}

.Modal_modal__P3_V5 h3 {
  font-size: xx-large;
  font-weight: bold;
}

.Modal_content__1VIbZ {
  padding: 1.5rem 3rem 4rem 3rem;
  max-height: 50vh;
  overflow-y: auto;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP {
  position: absolute;
  top: 1rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
}
.Modal_modal__P3_V5 button img {
  width: 1rem;
  height: auto;
}

@media (max-width: 992px) {
  .Modal_modal__P3_V5 {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.NavBar_navBarContainer__bTR3M {
  z-index: 2;
}

.NavBar_navBarBrand__1hw3w {
  position: relative;
  z-index: 2;
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.NavBar_connectBtn__1uOYH {
  cursor: pointer;
}
.NavBar_connectBtn__1uOYH:hover,
.NavBar_connectBtn__1uOYH:active,
.NavBar_connectBtn__1uOYH:focus {
  cursor: pointer !important;
}
.NavBar_disconnectBtn__1B1Vs {
  cursor: pointer;
}
.NavBar_disconnectBtn__1B1Vs:hover,
.NavBar_disconnectBtn__1B1Vs:active,
.NavBar_disconnectBtn__1B1Vs:focus {
  cursor: pointer !important;
}

.NavBar_greenStatusCircle__1zBA7 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #00d688;
  border-radius: 50%;
  margin-right: 5px;
}

.NavBar_nounsNavLink__1_qpP,
.NavBar_nounsNavLink__1_qpP:active,
.NavBar_nounsNavLink__1_qpP:visited {
  cursor: pointer;
  text-decoration: none !important;
  padding: 0.5rem 0.8rem !important;
  color: var(--brand-black) !important;
  /* font-family: -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-weight: 500;
}
.NavBar_nounsNavLink__1_qpP:hover {
  cursor: pointer;
  background: var(--brand-dark-red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.NavBar_testnetImg__322vL {
  width: auto;
  height: 45px;
}

.NavBar_ethXiFont__22MSp {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.NavBar_menuItem__2GaXq {
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  font-size: large;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none !important;
}
.NavBar_menuItem__2GaXq:hover {
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  background: var(--brand-dark-red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.NavBar_disconnectButton__qkFBI {
  cursor: pointer;
}
.NavBar_disconnectButton__qkFBI:hover {
  cursor: pointer;
  cursor: pointer;

  background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.WalletButton_walletButton__Q_aK0 {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
}
.WalletButton_walletButton__Q_aK0 img {
  margin-right: 10px;
  border-radius: 5px;
}
.WalletButton_walletButton__Q_aK0:hover,
.WalletButton_walletButton__Q_aK0:active,
.WalletButton_walletButton__Q_aK0:focus,
.WalletButton_walletButton__Q_aK0:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}

.WalletConnectModal_clickable__2UYOs {
  cursor: pointer;
}

.WithdrawModal_confirmText__1P1pd {
  text-align: center;
}

.WithdrawModal_withdrawTextRow__2cSFR {
  padding: 0px 20px 0px 20px;
}

.WithdrawModal_withdrawFundsButton__2ktjP {
  height: 3em;
  width: 50%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}

.WithdrawModal_infoText__1mCGY {
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.ClaimTokensModal_claimTokensButton__2mjHl {
  height: 3em;
  width: 50%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 30px 0px 10px 0px;
}

.ClaimTokensModal_tokenRowText__22tQX {
  margin: 0px;
  padding: 0px;
}

.ClaimTokensModal_infoText__38UVd {
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.ClaimTokensModal_row__2c7m7 {
  margin-bottom: 10px;
  padding: 8px 0px;
  font-weight: bold;
}

.ClaimTokensModal_row__2c7m7:nth-child(odd) {
  background-color: #f7f7f7;
}

.NetworkAlert_instructionsList__3y4zZ {
  padding-bottom: 10px;
}

.NetworkAlert_instructionsTitle__1s2EB {
  margin: 15px 0px 15px 0px;
}

.NetworkAlert_networkName__3o8kn {
  margin: 0px 0px 15px 0px;
}

.modal-content {
  border-radius: 24px;
}

.Noun_img__1GJxo {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  width: 100%;
}

.Auction_nounWrapper__3JSNc {
  align-self: flex-end;
  width: 100%;
  z-index: -1;
}
.Auction_nounContentCol__1o5ER {
  display: flex;
}

.Auction_pageContentWrapper__1Ns_6 {
  text-align: center;
}

.Auction_currentAuctionActivityContainer__2UNXB {
  margin-top: 5vh;
  text-align: left;
  z-index: 2;
  align-self: flex-end;
  /* for nouns stamp in safari browsers */
}

@media (max-width: 992px) {
  .Auction_currentAuctionActivityContainer__2UNXB {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }
}

.Auction_pastAuctionActivityContainer__fkexB {
  margin-top: 10vh;
  text-align: left;
}

@media (max-width: 992px) {
  .Auction_pastAuctionActivityContainer__fkexB {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }
}

.Auction_auctionActivityCol__3U2jw {
  padding-top: 2rem;
  align-self: start !important; /* maintains auction arrow buttons fixed from auction to auction  */
}

@media (max-width: 992px) {
  .Auction_auctionActivityCol__3U2jw {
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2rem;
  }
}

.Auction_noPaddingMargin__2Gyd4 {
  padding: 0px;
  margin: 0px;
}

.AuctionActivity_partyPaperContainer__mD8hC {
  background-color: white;
  border-bottom: none;
  padding-top: 20px;
  box-shadow: 0px -8px 8px rgb(0 0 0 / 20%);
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
}

@media (min-width: 993px) {
  .AuctionActivity_paperWrapper__15eGn {
    height: 460px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px;
  }
}

.AuctionActivity_partyPaperContainer__mD8hC:before {
  position: absolute;
  top: -20px;
  display: block;
  content: '';
  background: radial-gradient(circle, #ffffff00 12px, #ffffff 13px, white 5px);
  background-size: 44px 42px;
  background-position: 6px -22px;
  width: 100%;
  height: 40px;
  z-index: -1;
}

.AuctionActivity_floatingPaper__2ePCz {
  background-color: white;
  /* box-shadow: 0px -8px 8px rgb(0 0 0 / 20%); */
  padding-top: 20px;
  -webkit-filter: drop-shadow(0px 0px 0px white);
  filter: drop-shadow(0px 0px 0px white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  scrollbar-color: #cacfd2 rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
  width: 10px;
  padding: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eeeff2;
  border-radius: 24px;

  border: none;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #cacfd2;
  border-radius: 24px;
}

.AuctionActivity_auctionActivityContainer__1VGEL {
  padding: 0px;
  margin: 10px;
}

.AuctionActivity_informationRow__2BOSj {
  margin-bottom: 2rem;
}

.AuctionActivity_activityRow__1xuKY {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.AuctionActivity_modalHeader__2pkxA {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.AuctionActivity_modalTitleWrapper__2w2pt {
  color: var(--brand-black);
}

.AuctionActivity_modalTitleWrapper__2w2pt h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.AuctionActivity_modalHeader__2pkxA button {
  align-self: flex-start;
}

.AuctionActivity_modalHeaderNounImgWrapper__3boIZ {
  width: 150px;
  height: 150px;
}

/* come on safari what in the world */

/* https://stackoverflow.com/questions/41901346/div-with-position-fixed-not-displaying-inside-div-with-position-absolute-in-sa */

.AuctionActivity_nounsPartyStamp__1UtLE {
  position: absolute;
  top: -40px;
  right: -15px;
  z-index: -1;
  height: 200px;
  width: 200px;
  opacity: 0.5;
}

.AuctionActivity_winningAmount__2XUNA {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
}

.AuctionActivity_winningBidRow__SmEmB {
  margin-bottom: 20px;
}

/* Fix Firefox navigation arrow alignment issues */

@-moz-document url-prefix() {
  .AuctionActivity_colAlignCenter__3SaC2 {
    display: flex;
    align-items: center;
  }
}

.AuctionActivity_nounIdContainer__3SHps {
  margin: 10px 10px 10px 10px;
  font-family: 'Fredoka One';
  padding-left: 15px;
  display: flex;
}

.AuctionActivity_nounIdText__2-Nn7 {
  font-family: 'Fredoka One';
  /* background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 3em;
  padding: 0px;
  margin: 0px 0px 0px 0px;
  white-space: nowrap;
  color: #212529;
}

.AuctionActivity_buttonsWrapper__s8s85 {
  margin: 10px;
}

.AuctionActivity_fracVaultContainer__3C7BJ {
  margin: 5px 25px;
}

.AuctionActivity_fracVaultButton__2B-dD {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 5px 0px 5px 0px;
}

.AuctionActivity_settleAuctionButton__1eb0H {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}

.AuctionActivity_ethAddressPadding__1_YhT {
  padding-left: 1em;
  padding-right: 0px;
}

.AuctionActivity_noMarginPadding__1zTKb {
  margin: 0px !important;
  padding: 0px !important;
}

.AuctionActivity_addressText__uX58v {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 40px;
  font-weight: 700;
  color: #212529;
}

.AuctionActivity_bidText__2RkMp {
  font-weight: 700;
  font-size: 1.125rem;
}

.AuctionActivity_dateContainer__2hwAU {
  display: flex;
}

.AuctionActivity_noPaddingMargin__2VkOt {
  margin: 0px;
  padding: 0px;
}

.AuctionActivity_auctionTimerContainer__1kbX2 {
  margin: 0px 10px 10px 10px;
  padding-left: 15px;
}

.AuctionActivity_currentAuctionActivityContainer__28m04 {
  margin-top: 5vh;
  text-align: left;
  z-index: 2;
  align-self: flex-end;
  /* for nouns stamp in safari browsers */
}

@media (max-width: 992px) {
  .AuctionActivity_floatingPaper__2ePCz {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 20px;
  }
  .AuctionActivity_wrapper__3UpQR {
    padding: 1rem;
  }
  .AuctionActivity_auctionTimerCol__2oKfX {
    padding-left: 15px;
  }
  .AuctionActivity_nounsPartyStamp__1UtLE {
    position: absolute;
    top: 70px;
    right: 0px;
    z-index: -1;
    height: 150px;
    width: 150px;
    opacity: 0.5;
  }
  .AuctionActivity_winningAmount__2XUNA {
    margin: 0px !important;
    padding: 0px 0px 15px 0px !important;
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
  }

  .AuctionActivity_currentAuctionActivityContainer__28m04 {
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }

  .AuctionActivity_paperWrapper__15eGn {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 0px;
  }
}

.AuctionActivity_settleAuctionRow__o9KYz {
  margin: 0px 10px 0px 10px;
}

.AuctionTimer_timerWrapper__3c10Z {
  display: flex;
  text-align: center;
  margin: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
  white-space: nowrap;
}

.AuctionTimer_timerSection__2RlJK {
  margin-right: 0.5rem;
}

.AuctionTimer_noPaddingMargin__1utxr {
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}

.AuctionTimer_timerText__2ibfd {
  font-weight: 700;
  font-size: 1.125rem;
}

.AuctionTimer_timeLabels__25y_A {
  font-size: 1.75rem;
}

.CurrentBid_section__2oRi6 span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.CurrentBid_noMarginPadding__3Rcdj {
  margin: 0px !important;
  padding: 0px !important;
}

.CurrentBid_addressText__Dlsmd {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 40px;
  font-weight: 700;
  color: #212529;
}

.CurrentBid_bidText__2T34z {
  font-weight: 700;
  font-size: 1.125rem;
  color: #5ec0fa;
  margin: 0px !important;
  padding: 0px !important;
  text-align: center;
}

.CurrentBid_winningText__9gxfM {
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0px !important;
  padding: 0px !important;
}

.CurrentBid_winningAmount__Gedjy {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
}

.CurrentBid_partyWonText__O9mgo {
  background: -webkit-linear-gradient(10deg, #00ffa3 12%, #57cdff 25%, #f68eff 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-weight: 700;
}

.CurrentBid_leadingBid__3Hs_q {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
  text-align: center;
}

@media (max-width: 992px) {
  .CurrentBid_leadingBid__3Hs_q {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 1.75rem;
    font-weight: 700;
    color: #212529;
    text-align: center;
    white-space: nowrap;
  }
  .CurrentBid_winningAmount__Gedjy {
    margin: 0px !important;
    padding: 0px 0px 15px 0px !important;
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
  }
}

.TruncatedAmount_ethXiFont__3Rdtn {
  font-weight: 700;
}

.Winner_bidText__1X1rH {
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0px !important;
  padding: 0px !important;
  color: #212529;
}

.Winner_addressText__1SOEy {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
}

/* Help prevent overflow */
.Winner_ethAddressPadding__1XsHp {
  padding-left: 1em;
  padding-right: 0px;
}

.Winner_partyWonText__2-yHy {
  background: -webkit-linear-gradient(10deg, #00ffa3 12%, #57cdff 35%, #f68eff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.25rem;
  font-weight: 700;
}

@media (max-width: 993px) {
  .Winner_addressText__1SOEy {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
  }
}

.PartyProgressBar_partyVaultWrapper__1pcYD {
  margin: 20px 25px 25px 25px;
}
.PartyProgressBar_ethNeededText__gFt_O {
  font-size: 18px;
  color: rgba(33, 37, 41, 0.2);
}

.PartyProgressBar_partyTrackerText__2y2g_ {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2px;
}

.PartyProgressBar_partyGoalText__1ZDrw {
  font-size: 1rem;
  font-weight: 500;
  text-align: end;
  color: rgb(33, 37, 41);
  margin-bottom: 0px;
  align-items: center;
}

.PartyProgressBar_ethXiFont__PKzek {
  /* background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: rgba(104, 116, 127, 0.3);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.PartyProgressBar_progressBarContainer__1j01Z {
  display: grid;
}

.PartyProgressBar_progressBar__CGlr7 {
  grid-column: 1;
  grid-row: 1;
}

.PartyProgressBar_blurProgressBar__3QBk3 {
  -webkit-filter: blur(6px);
          filter: blur(6px);
  z-index: -2;
}

@media (max-width: 992px) {
  .PartyProgressBar_ethNeededText__gFt_O {
    text-align: end;
    font-size: 0.9em;
    color: rgba(33, 37, 41, 0.2);
  }
  .PartyProgressBar_partyTrackerText__2y2g_ {
    font-size: 1rem;
    font-weight: bold;
  }
}

.progress {
  border-radius: 50px;
  overflow: visible;
  height: 12px;
}

/* 19eea1 */
.bg-partyGradient {
  background-image: linear-gradient(90deg, #00ffa3 0%, #57cdff 49.53%, #f68eff 100%);
  /* border-radius: 50px; */
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.bg-partyVaultLess {
  background-image: linear-gradient(90deg, #ccffed 0%, #00ffa3 100%);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  z-index: 2;
}

.bg-partyVaultMore {
  background-image: linear-gradient(90deg, #ccffed 0%, #00ffa3 100%);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.bg-currentBidLess {
  background-image: linear-gradient(90deg, #cbecff 0%, #68c7ff 100%);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  z-index: 2;
}
.bg-currentBidMore {
  background-image: linear-gradient(90deg, #cbecff 0%, #68c7ff 100%);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.progressBar {
  grid-column: 1;
  grid-row: 1;
}

/* Rectangle 149 */

.ConnectWalletButton_connectWalletButton__1TW63 {
  height: 3em;
  width: 100%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: #212529;
  /*background:
    linear-gradient(180deg, #ffffff75 15.22%, #ffffff00 56.54%),
    linear-gradient(90.72deg, #00ffa3 -7.78%, #57cdff 35.54%, #f68eff 118.48%);*/
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 0px 0px 10px 0px;

  transition: all 0.3s ease-in-out;
}

.ConnectWalletButton_connectWalletButton__1TW63:hover {
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
}

.ConnectWalletButton_connectWalletButton__1TW63:disabled {
  background: #f0f1f4;
  color: #d2d5da;
}

/*
.connectWalletButton:hover {
  background:
    linear-gradient(180deg, #ffffff7d 22.22%, #ffffff00 69.54%),
    linear-gradient(90.72deg, #3fffba -33.78%, #81d9ff 35.54%, #f8aaff 118.48%);
  box-shadow: 0px 6px 12px rgb(0 0 0 / 20%);
}*/

.AddFundsModal_infoText__3nOHi {
  text-align: justify;
  font-size: 0.8em;
}

.AddFundsModal_warningText__23eQA {
  text-align: justify;
  font-size: 0.8em;
}

.AddFundsModal_depositText__1Mrfv {
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.AddFundsModal_bidInput__2TWWo {
  border-radius: 25px !important;
  width: 50%;
  padding: 20px;
  width: 200px;
  height: 15px;
}

.AddFundsModal_customPlaceholder__1g3t5 {
  position: absolute;
  top: 25%;
  left: 80%;
  font-weight: bold;
  color: #aaa;
  z-index: 3;
}

.AddFundsModal_inputContainer__pqiVb {
  margin: 10px 0px 10px 0px;
}

.AddFundsModal_addFundsButton__pmChR {
  height: 3em;
  width: 100%;
  /* padding: 10.5px 50px; */
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  /* filter: blur(12px); */
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}
.AddFundsModal_minimumDeposit__WtgE8 {
  font-size: 0.8rem;
  text-align: left;
  color: rgba(33, 37, 41, 0.534);
  padding: 0px;
  margin: 0px 0px 0px 20px;
}

.AddFundsModal_minimumDepositErrorMessage__3muaU {
  font-size: 0.8rem;
  text-align: left;
  color: rgba(255, 0, 0);
  padding: 0px;
  margin: 0px 0px 0px 20px;
}

.Bid_bidWrapper__1NsAz {
  margin: 1rem 0;
}

.Bid_bidBtn__2MzFj {
  margin-left: 1rem;
  width: 40%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.Bid_bidBtn__2MzFj:hover,
.Bid_bidBtn__2MzFj:active,
.Bid_bidBtn__2MzFj:focus,
.Bid_bidBtn__2MzFj:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}
.Bid_minBidCopy__1WI1j {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}
.Bid_bidInput__39un5 {
  width: 60%;
  height: 3rem;
  color: black;
  border: 1px solid #aaa !important;
  border-radius: 25px !important;
  background-color: white;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}
.Bid_bidInput__39un5:focus {
  border: none !important;
  border: 1px solid #aaa !important;
}
.Bid_customPlaceholder__3KOvn {
  position: absolute;
  top: 25%;
  left: 85%;
  font-weight: bold;
  color: #aaa;
  z-index: 3;
}
.Bid_bidBtnAuctionEnded__1zL5T {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.Bid_bidBtnAuctionEnded__1zL5T:hover,
.Bid_bidBtnAuctionEnded__1zL5T:active,
.Bid_bidBtnAuctionEnded__1zL5T:focus,
.Bid_bidBtnAuctionEnded__1zL5T:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.Bid_bidInputAuctionEnded__3sHIA {
  display: none;
}

.Bid_bidInput__39un5:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.Bid_bidInput__39un5::-webkit-outer-spin-button,
.Bid_bidInput__39un5::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.Bid_bidInput__39un5[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 992px) {
  .Bid_bidInput__39un5 {
    border-color: #c3c3c3;
  }
  .Bid_customPlaceholder__3KOvn {
    left: 44%;
  }
}

.Bid_infoText__D-9fG {
  text-align: left;
  font-size: 0.9em;
}

.Bid_placePartyBidButton__1P_K0 {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: bold;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
}

.PartyButtons_buttonsWrapper__JGTK9 {
  margin: 10px;
}

.PartyButtons_auctionInfoWrapper__2ldbX {
  background-color: white;
  border-radius: 12px;
  padding: 15px;
}

.PartyButtons_connectWalletButton__3oTWW {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  background: #3210ff;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  font-size: 1em;
}
.PartyButtons_invitePartyButton__2q54X {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: bold;
  color: #fff;
  background: black;
  cursor: pointer;
  z-index: 0;
  border-radius: 12px;
  font-size: 1em;
}

.PartyButtons_noLeftPadding__1-kEx {
  padding-left: 0px;
  padding-right: 10px;
}

.PartyButtons_noRightPadding__yRvkR {
  padding-left: 10px;
  padding-right: 0px;
}

.PartyGuestList_guestListContainer__6w2Sc {
  margin: 10px 10px 10px 10px;
  padding: 15px;
}

.PartyGuestList_guestListBidsContainer__2qK2r {
  max-height: 20vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.PartyGuestList_contributionsBlurb__3wZEi {
  font-size: 0.8em;
}

.PartyGuestList_partyMembersHeadingContainer__3H1G1 {
  padding: 0px;
  margin: 0px 0px 5px 0px;
}

.PartyGuestList_partyMembersHeadingText__Q5DrK {
  vertical-align: middle;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
}

.PartyGuestList_noPaddingMargin__3_y2L {
  padding: 0px;
  margin: 0px;
}

.PartyGuestList_jazzicon__2IwnH {
  height: 30px;
  width: 30px;
  border: 2px solid black;
  padding: 0;
  margin: 0;
  border-radius: 30px;
  margin-right: 10px;
  margin-top: -3px;
}

/* User Bids classes */

.PartyGuestList_userBidsContainer__8qq0r {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 3px;
  overflow: auto;
  height: 37.5vh;
  border-radius: 4px;
}

.PartyGuestList_altWrapper__10R5U {
  text-align: center;
}

.PartyGuestList_bidCollection__1rtOJ {
  display: grid;
  text-align: start;
  list-style-type: none;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0;
}

.PartyGuestList_bidRow__Ge_YZ {
  /* padding: 0.5rem; */
  /* border-bottom: 1px solid rgba(211, 211, 211, 0.322); */
  border-radius: 4px;
  background-color: #eeeff2;
  padding: 9px 14px 6px 8px;
  border-radius: 50px;
}

.PartyGuestList_bidItem__3pAhS {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PartyGuestList_leftSectionWrapper__tAEmI {
  display: flex;
  flex-direction: column;
}

.PartyGuestList_bidder__UpbYa {
  color: var(--brand-black);
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  align-items: center;
}

.PartyGuestList_bidDate__g8eka {
  color: gray;
}

.PartyGuestList_rightSectionWrapper__2iGxt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.PartyGuestList_bidAmount__2FDuP {
  color: var(--brand-black);
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
}

.PartyGuestList_linkSymbol__2zE2V,
.PartyGuestList_linkSymbol__2zE2V a {
  color: var(--brand-black);
  margin-bottom: 2px;
}

.PartyGuestList_linkSymbol__2zE2V a:hover {
  color: gray;
}

.PartyGuestList_partyBidsTitle__3H7i8 {
  margin: 15px 0px 0px 0px;
  padding: 0px;
  font-size: 1.5rem;
  text-align: center;
}

@media (max-width: 992px) {
  .PartyGuestList_bidAmount__2FDuP {
    color: var(--brand-black);
    margin-right: 0.25rem;
    font-size: 0.9rem;
    font-weight: 700;
  }

  .PartyGuestList_bidder__UpbYa {
    color: var(--brand-black);
    display: flex;
    font-size: 0.9rem;
    font-weight: 700;
  }
}

.AuctionNavigation_leftArrow__1NOSB {
  display: inline-block;
  border: none;
  background-color: #6db3f2;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 3;
  align-self: end;
  /* margin-left: 10px;
  margin-top: 10px; */
}
.AuctionNavigation_rightArrow__33rdI {
  display: inline-block;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 3;
  align-self: end;
  /* margin-top: 12px; */
}

.AuctionNavigation_rightArrow__33rdI img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.AuctionNavigation_leftArrow__1NOSB:hover,
.AuctionNavigation_rightArrow__33rdI:hover {
  opacity: 0.9;
}
.AuctionNavigation_leftArrow__1NOSB:disabled,
.AuctionNavigation_rightArrow__33rdI:disabled {
  opacity: 0.2;
}
/*
@media (max-width: 992px) {
  .leftArrow {
    margin-left: 1rem;
  }
}*/

.AuctionActivityDateHeadline_dateWrapper___m_8j {
  margin: 15px 20px 0px 10px;
  padding: 0px 0px 0px 15px;
}

.AuctionActivityDateHeadline_noPaddingMargin__13pBO {
  padding: 0px;
  margin: 0px;
}

.AuctionActivityDateHeadline_dateText__21Ix2 {
  margin: 0px;
  padding: 0px;
  font-size: 1.125rem;
  font-weight: 700;
}

@media (max-width: 992px) {
  .AuctionActivityDateHeadline_dateText__21Ix2 {
    margin: -10px 0px 0px 0px;
    padding: 0px;
    font-size: 1.125rem;
    font-weight: 700;
  }
}

.AuctionStatus_statusIndicator__3iB3p {
  text-align: center;
  background: white;
  border-radius: 50px;
  align-items: center;
  font-size: 16px;
  padding: 4px 20px;
  display: inline-block;
  color: #fd5858;
  /* box-shadow: 0px 0px 4px rgb(150 150 150 / 60%); */
  margin-bottom: 6px;
  font-weight: bold;
}

.AuctionStatus_failStatus__2PGlD {
  color: #fd5858;
}

.AuctionStatus_successStatus__1OKr1 {
  background: -webkit-linear-gradient(10deg, #00ffa3 -22%, #57cdff 45%, #f68eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: white;
  font-weight: bold;
}

.AuctionStatus_statusText__1csqa {
  font-size: 1.125rem;
  padding: 0px 0px 0px 10px;
  color: #afb3b7;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 22px;
}

.AuctionStatus_statusTextTitle__kQ3uL {
  font-size: 1.25rem;
  padding: 0px 0px 0px 10px;
  margin: 0px;
  color: #212529;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 22px;
}

.AuctionStatus_statusRow__2ywEs {
  padding: 12px;
  border-radius: 20px;
  margin: 0px 0px 20px 0px;
  background: #ffffff85;
}

.AuctionStatus_rectangle__2Xf-7 {
  position: absolute;
  width: 6px;
  height: 100%;
  top: 0px;
  left: 5px;
  background: linear-gradient(360deg, #00ffa3 0%, #57cdff 49.53%, #f68eff 100%);
  border-radius: 4px;
}

@media (max-width: 993px) {
  .AuctionStatus_statusText__1csqa {
    font-size: 1rem;
    padding: 0px 0px 0px 10px;
    color: #afb3b7;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 22px;
  }

  .AuctionStatus_statusTextTitle__kQ3uL {
    font-size: 1.125rem;
    padding: 0px 0px 0px 10px;
    margin: 0px;
    color: #212529;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 22px;
  }
}

.SettleAuction_confirmText__187Ee {
  text-align: center;
  font-size: 0.9em;
}

.SettleAuction_settleAuctionButton__1TdsY {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -7.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
  transition: all 0.3s ease-in-out;
}

.SettleAuction_settleAuctionButton__1TdsY:hover {
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
}

.PartyVault_vaultText__1MF1v {
  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;
  color: #19eea1;
}

.PartyVault_noMarginPadding__1a_vl {
  margin: 0px !important;
  padding: 0px !important;
}

.PartyVault_ethAddressPadding__IK65r {
  padding-left: 1em;
  padding-right: 0px;
}

.PartyVault_addressText__2Rb2N {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 2.25rem;
  font-weight: 700;
  color: #212529;
  text-align: center;
}

@media (max-width: 992px) {
  .PartyVault_addressText__2Rb2N {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 1.75rem;
    font-weight: 700;
    color: #212529;
    text-align: center;
    white-space: nowrap;
  }
}

.PartyVault_ethXiFont__32ncx {
  font-weight: 700;
}

.UpdatedConfetti_confettiContainer__1fU-O {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
}

.OwnSocialCursor_cursor__2Q5_H {
  z-index: 99999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  color: var(--brand-black);
  border-radius: 20px;
}

.OwnSocialCursor_cursor__2Q5_H i {
  font-style: normal;
  font-size: x-large;
}

.OwnSocialCursor_cursor__2Q5_H input {
  border: 0;
  margin-left: 4px;
  font-size: small;
  width: 200px;
  background-color: var(--white);
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90.72deg, #00ffa3 20%, #57cdff 33.54%, #f68eff 90%) border-box;
  padding: 4px 12px;
  border-radius: 20px;
  color: var(--brand-black);
}

.OwnSocialCursor_cursor__2Q5_H input:focus {
  outline: none;
}

.OwnSocialCursor_nounHeadImage__2-qA7 {
  height: 60px;
  width: 60px;
  margin-left: -20px;
  margin-top: -2px;
}

.VisitorSocialCursor_cursor__3LWso {
  z-index: 99998;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); */
  cursor: default;
}

.VisitorSocialCursor_cursor__3LWso i {
  font-style: normal;
  font-size: x-large;
}

.VisitorSocialCursor_cursor__3LWso span {
  overflow: hidden;
  margin-left: 4px;
  font-weight: bold;
  font-size: medium;
  white-space: nowrap;
  color: black;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90.72deg, #00ffa3 20%, #57cdff 33.54%, #f68eff 90%) border-box;
  padding: 0px 10px;
  border-radius: 20px;
}

.VisitorSocialCursor_triangle__p3Qx0 {
  position: relative;
  text-align: left;
  left: -18px;
  top: -18px;
  margin-right: -6px;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
}

.VisitorSocialCursor_triangle__p3Qx0:before,
.VisitorSocialCursor_triangle__p3Qx0:after {
  content: '';
  position: absolute;
  background: linear-gradient(90.72deg, #00ffa3 -33.78%, #57cdff 35.54%, #f68eff 118.48%);
}

.VisitorSocialCursor_triangle__p3Qx0,
.VisitorSocialCursor_triangle__p3Qx0:before,
.VisitorSocialCursor_triangle__p3Qx0:after {
  width: 7px;
  height: 7px;
  border-top-right-radius: 60%;
}

.VisitorSocialCursor_triangle__p3Qx0 {
  -webkit-transform: rotate(-110deg) skewX(-30deg) scale(1, 0.866);
          transform: rotate(-110deg) skewX(-30deg) scale(1, 0.866);
}

.VisitorSocialCursor_triangle__p3Qx0:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}

.VisitorSocialCursor_triangle__p3Qx0:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.VisitorSocialCursor_nounHeadImage__kUEmJ {
  height: 60px;
  width: 60px;
  margin-left: -20px;
  margin-top: -5px;
}

.Section_container__1zQTr {
  padding: 2rem 0rem;
}

.Documentation_headerWrapper__xln_n h1 {
  font-size: 4rem;
  color: #212529;
  font-weight: 900;
  padding: 0rem 1.25rem;
}
.Documentation_headerWrapper__xln_n p {
  margin: 3rem 0;
  padding: 0rem 1.25rem;
}

.Documentation_faq__3F1MW {
  font-weight: 900;
  font-size: 2.5rem;
  color: #212529;
}

.Documentation_teamRewards__39VQJ {
  font-weight: 900;
  font-size: 2.5rem;
  color: #212529;
}

@media (max-width: 992px) {
  .Documentation_headerWrapper__xln_n {
    padding: 1rem;
  }

  .Documentation_headerWrapper__xln_n h1 {
    font-family: 'Fredoka One';
    font-size: 4rem;
    background: -webkit-linear-gradient(10deg, #00ffa3 10%, #57cdff 40%, #f68eff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .Documentation_faq__3F1MW {
    font-family: 'Fredoka One';
    font-size: 2.5rem;
    color: #212529;
  }
  .Documentation_teamRewards__39VQJ {
    font-family: 'Fredoka One';
    font-size: 2.5rem;
    color: #212529;
  }
}

.Documentation_card__1TWtt {
  border: none;
}

.Documentation_card__1TWtt,
.Documentation_headerWrapper__xln_n {
  font-size: 1.3rem;
}
.Documentation_cardHeader__xv_vO {
  border: none;
  background-color: transparent;
  font-size: 2.5rem;
  cursor: pointer;
}

.Documentation_documentationParagraph__aeZcR {
  margin-bottom: 3rem;
  font-size: 1.125rem;
}

.Documentation_collectiveParagraph__1rJ3_ {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.Documentation_weAreTheBuildersBaby__1azJz {
  margin-bottom: 3rem;
}

/* .cardHeader h2 {
  font-family: 'Fredoka One';
  font-size: 2.5rem;
  background: -webkit-linear-gradient(10deg, #00ffa3 5%, #57cdff 10%, #f68eff 15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.Link_link__3euZY,
.Link_link__3euZY:hover,
.Link_link__3euZY:active {
  color: var(--brand-dark-red);
}
.Link_link__3euZY:hover {
  text-decoration: underline;
}

.Banner_wrapper__1YbFc {
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.Banner_wrapper__1YbFc h1 {
  text-align: center;
  font-size: 4rem;
  color: #212529;
  line-height: 1;
  font-weight: 900;
}

.Banner_instructionsRow__cLNEZ {
  margin-bottom: 3rem;
}

@media (max-width: 992px) {
  .Banner_wrapper__1YbFc {
    padding: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  .Banner_wrapper__1YbFc h1 {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .Banner_wrapper__1YbFc h1 {
    font-size: 6rem;
  }
}

.Banner_instructionText__3TDKU {
  display: grid;
  grid-auto-flow: column dense;
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
  width: 100%;
  padding: 8px;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
  font-weight: 700;
  font-size: 1.125rem;
}

.Banner_instructionText__3TDKU div {
  display: grid;
  grid-auto-flow: column dense;
}

.Banner_instructionText__3TDKU span {
  font-family: 'Fredoka One';
  margin-top: -6px;
  margin-right: 8px;
  font-size: 1.75rem;
  color: white;
  text-align: center;
  font-weight: 900;
  border-radius: 50%;
  text-align: center;
  width: 45px;
  height: 45px;
  padding: 2px;
  background: -webkit-linear-gradient(330deg, #00ffa3, #57cdff, #f68eff 90%);
}

@media (max-width: 992px) {
  .Banner_instructionText__3TDKU {
    /* Todo:  Stack vertically */
  }
}

.HistoryCollection_historyCollection__2Shui {
  display: grid;
  grid-auto-flow: column dense;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  width: 100%;
  max-height: 175px;
  padding: 8px;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
  max-width: 1700px;
}

.HistoryCollection_rtl__7jPW3 {
  direction: ltr;
}

.HistoryCollection_historyCollection__2Shui div {
  width: 146px;
  margin: 2px;
}

@media (max-width: 992px) {
  .HistoryCollection_historyCollection__2Shui {
    grid-template-columns: repeat(10, 150px);
    overflow-x: auto;
  }
}

.Proposals_proposals__2mg9W {
  display: flex;
  flex-direction: column;
}

.Proposals_proposals__2mg9W > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.Proposals_heading__26OQ5 {
  display: inline-block;
  font-size: 2rem;
  margin: 0 !important;
}

.Proposals_createProposalLink__1VCk5 {
  padding: 0.9rem 1.5rem;
  background-color: gray;
  border-radius: 5px;
  border: 0 none;
  font-size: large;
  font-weight: normal;
  cursor: default;
}

.Proposals_createProposalLink__1VCk5:hover {
  background-color: rgb(104, 104, 104);
}

.Proposals_proposalLink__3idLu {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Governance_heading__MOhD3 {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.Governance_subheading__222R0 {
  color: var(--brand-dark-green);
  font-weight: bold;
  font-size: 1.2rem;
}

/* p {
  padding-top: 1rem;
} */

.CreateProposal_createProposalPage__3qx3H a {
  color: var(--brand-dark-red);
}

.CreateProposal_createProposalForm__1UHgT {
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: white;
}

.CreateProposal_heading__e7kWw {
  margin: 1rem 0;
}

.CreateProposal_section__2A56Y {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.CreateProposal_addTransactionButton__3U7s3,
.CreateProposal_createProposalButton__UHWVW {
  margin-top: 1rem;
}

.ProposalEditor_proposalEditor__19xXK {
  margin-top: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A,
.ProposalEditor_bodyInput__20KWn {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A {
  font-size: 1.25rem;
}

.ProposalEditor_bodyInput__20KWn {
  min-height: 340px;
}

.ProposalEditor_divider__38QOx {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.ProposalTransactions_popover__3fRG9 {
  max-width: 600px;
}

.ProposalTransactions_transactionDetails__2EchO {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ {
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ img {
  width: 1rem;
}

.ProposalTransactionFormModal_transactionFormModal__2GUdX {
  max-width: 600px;
}

.ProposalTransactionFormModal_stepProgressBar__4Na9W {
  padding: 0 0 2rem 0 !important;
}

.ProposalTransactionFormModal_inputGroupText__3ruwH {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
}

.ProposalTransactionFormModal_functionName__2XKIf {
  white-space: nowrap;
}

.Vote_votePage__G1H10 a {
  color: var(--brand-dark-red);
}

.Vote_proposal__1pjGZ {
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: white;
}

.Vote_backArrow__3z9lF {
  height: 1rem;
}

.Vote_votingButton__3PrIp {
  margin-top: 1rem;
}

.Vote_voteCountCard__11har {
  margin-top: 1rem;
}

.Vote_proposalId__1ti1o {
  margin: 1rem 0;
}

.Vote_voteCountCard__11har p {
  display: flex;
  justify-content: space-between;
}

.Vote_section__3S8l3 {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.Vote_markdown__2g5aA h1 {
  font-size: 2rem;
  margin-top: 1rem;
  font-weight: bold;
}

.Vote_markdown__2g5aA h3 {
  font-size: 1.5rem;
}

.Vote_voterIneligibleAlert__2jDfQ {
  margin: 1rem 0 0 0;
}

.VoteModal_voteModal__19gv1 {
  max-width: 380px;
}

.VoteModal_voteModalText__3_pyA {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0;
}

.NoundersPage_noundersPage__2BU9Y h1,
.NoundersPage_noundersPage__2BU9Y h2,
.NoundersPage_noundersPage__2BU9Y h3 {
  font-family: 'Londrina Solid';
}

.NoundersPage_noundersPage__2BU9Y h2 {
  margin-bottom: 2rem;
}

.NoundersPage_noundersPage__2BU9Y a {
  color: var(--brand-black);
}
.NoundersPage_noundersPage__2BU9Y a:hover {
  color: var(--brand-dark-red);
}

.NoundersPage_noundersPage__2BU9Y img {
  border-radius: 50%;
  width: 50%;
}

.NoundersPage_marginBottom__dDAL1 {
  margin-bottom: 0rem;
}

.NoundersPage_bioGroup__26YGW {
  margin-bottom: 2rem;
  text-align: center;
}

.NoundersPage_bioGroup__26YGW img {
  margin-bottom: 1rem;
}

.NoundersPage_twitterIcon__3L0yj {
  width: 20px;
  color: #aaa;
}

.NoundersPage_card__1wIOD {
  border: none;
}

.NoundersPage_card__1wIOD,
.NoundersPage_headerWrapper__1PZ9J {
  font-size: 1.3rem;
}
.NoundersPage_cardHeader__1GQQt {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Footer_footerSignature__3KHrj {
  margin: 0 auto;
  font-family: 'Londrina Solid';
  font-size: 1rem;
}
.Footer_footerSignature__3KHrj a {
  text-decoration: none;
  color: black;
  margin: 0 5px;
}
.Footer_footerSignature__3KHrj a:hover {
  text-decoration: underline;
  -webkit-animation-name: Footer_slidein__1VljP;
          animation-name: Footer_slidein__1VljP;
}
.Footer_footerSignature__3KHrj img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@-webkit-keyframes Footer_slidein__1VljP {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes Footer_slidein__1VljP {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.Footer_cursorInstructions__1Rfod {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: rgba(128, 128, 128, 0.75);
}

@media screen and (max-width: 600px) {
  .Footer_cursorInstructions__1Rfod {
    visibility: hidden;
   
    display: none;
  }
}

.Footer_cursorInstructions__1Rfod p {
  margin: 0px;
  padding: 2.5px;
  font-size: .7em;
}

.Footer_codeText__hDeiU {
  background-color: #b1b1b1;
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
}

.Footer_codeText__hDeiU:hover {
  background-color: #3f3f3f;
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
}

