.confirmText {
  text-align: center;
  font-size: 0.9em;
}

.settleAuctionButton {
  height: 3em;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  color: #fff;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  font-size: 1em;
  background: linear-gradient(90.72deg, #00ffa3 -7.78%, #57cdff 35.54%, #f68eff 118.48%);
  border-radius: 24px;
  margin: 20px 0px 10px 0px;
  transition: all 0.3s ease-in-out;
}

.settleAuctionButton:hover {
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
}
